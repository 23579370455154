import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import routes from "./Routes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/signup" element={<SignUp />} />
              <Route path="/test" element={<Testing />} />
              <Route path="/signin" element={<SignIn />} /> */}
          {routes.map((route) => {
            return (
              <Route
                key={route.id}
                path={route.path}
                element={<Layout component={route.element} />}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
