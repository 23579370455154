import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: "http://192.168.1.90:9093/",
  baseURL: "https://b1.bhaaraterp.com/",
});

axiosInstance.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem("token");
    config.headers = {
      Token: "",
      Authorization: "1f3b587d40a217cec89c8987cbe5e2084d27b89b",
      ...config.headers,
    };
    return config;
  },
  (err) => Promise.reject(err)
);

export default axiosInstance;
