import axios from "axios";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Header from "../../Shared/Header";

export default function Subscription() {
  const storeId = localStorage.getItem("store_id");
  const [data, setData] = useState([]);

  const getData = () => {
    axios
      .get(
        `https://b1.bhaaraterp.com/customer-pannel/customer-service-api-in-bhaaraterp/?page=1&store_id=${storeId}`,
        {
          headers: {
            authorization: localStorage.getItem("customersToken"),
          },
        }
      )
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    storeId && getData();
  }, [storeId]);
  return (
    <>
      <Header />
      <div className="flex flex-col justify-center items-center lg:px-20 px-2 my-10 !mt-20">
        <p className="my-5 text-3xl font-bold underline"> Subscription List</p>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="!bg-violet-500">
              <TableRow>
                <TableCell className="!font-bold">Date </TableCell>
                <TableCell className="!font-bold">Service Name</TableCell>
                <TableCell className="!font-bold">Service plan</TableCell>
                <TableCell className="!font-bold">SAC</TableCell>
                <TableCell className="!font-bold">Quantity</TableCell>
                <TableCell className="!font-bold">Bar Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((i) => (
                <TableRow
                  key={i.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className="hover:bg-violet-100"
                >
                  <TableCell component="th" scope="row">
                    {i?.sales_order?.sales_order_date.slice(0, 10)}
                  </TableCell>
                  <TableCell>{i?.service.service_name}</TableCell>
                  <TableCell>{i?.service?.service_plan}</TableCell>
                  <TableCell>{i?.service?.sac}</TableCell>
                  <TableCell>{i?.service?.quantity}</TableCell>
                  <TableCell>
                    <img
                      className="w-20 h-8 object-contain"
                      alt="f"
                      src={i?.service?.service_barcode_image}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
