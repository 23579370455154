import React, { useEffect, useState } from "react";
import Header from "../../Shared/Header";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/API_URLS";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { Pagination, PaginationItem, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export default function DetailsPage() {
  const { id, id1 } = useParams();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [page1, setPage1] = React.useState(1);
  const [page, setpage] = useState("");
  const [typeId, setTypeId] = useState(id1);
  // const [see, setSee] = useState(false);
  const navgate = useNavigate();

  const handleChange = (event, value) => {
    setPage1(value);
  };

  const ServiceSubTypeListr = () => {
    axiosInstance
      .get(API_URLS.serviceSubTypeList + `?service_type_id=${id}&page=${page1}`)
      .then((response) => {
        setData1(response.data.data);
        setpage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    ServiceSubTypeListr();
  }, [id, page1]);

  useEffect(() => {
    setTypeId(id1 === "0" ? data1?.[0]?.id : id1);
  }, [data1]);

  const ServiceTypeListr = () => {
    axiosInstance
      .get(API_URLS.serviceTypeListWithId + `?service_sub_type_id=${typeId}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    typeId && ServiceTypeListr();
  }, [typeId]);

  // const abc = () => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  console.log(page1, "page1");
  return (
    <>
      <Header />
      <div className="my-28 px-5">
        <div className="lg:flex flex-row relative  ">
          <div className="flex flex-col lg:w-[35%] px-5     ">
            <p className="text-4xl font-bold  ">
              {data?.service_sub_type?.name}
            </p>
            {/* <div className="flex gap-5 my-3">
              <svg
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.333 10a8.333 8.333 0 11-16.667 0 8.333 8.333 0 0116.667 0zm-7.894-4.694A.476.476 0 009.999 5a.476.476 0 00-.438.306L8.414 8.191l-2.977.25a.48.48 0 00-.414.342.513.513 0 00.143.532l2.268 2.033-.693 3.039a.51.51 0 00.183.518.458.458 0 00.528.022L10 13.298l2.548 1.629a.458.458 0 00.527-.022.51.51 0 00.184-.518l-.693-3.04 2.268-2.032a.513.513 0 00.143-.532.48.48 0 00-.415-.342l-2.976-.25-1.147-2.885z"
                  fill="#572AC8"
                ></path>
              </svg>
              <p className="underline">4.78 (4.2 M bookings)</p>
            </div> */}
            <div className="p-3 flex justify-between bg-gray-100 rounded-md">
              <div className="flex gap-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 16 16"
                  className="h-4 w-4"
                >
                  <path
                    d="M11.307 2.001a3.83 3.83 0 00-1.524.272 3.83 3.83 0 00-1.292.853l-.471.472-.343-.344c-1.431-1.434-3.83-1.689-5.404-.27a3.845 3.845 0 00-1.272 2.757 3.852 3.852 0 001.122 2.822l5.088 5.1a1.14 1.14 0 001.616 0l4.961-4.972c1.431-1.434 1.685-3.839.269-5.416a3.828 3.828 0 00-1.245-.921A3.83 3.83 0 0011.305 2z"
                    fill="#00539a"
                  ></path>
                  <path
                    d="M11.5 4l.424 1.076L13 5.5l-1.076.424L11.5 7l-.424-1.076L10 5.5l1.076-.424L11.5 4z"
                    fill="#fff"
                  ></path>
                </svg>
                <p className="text-sm">
                  UC Professional {data?.service_sub_type?.name} Guide
                </p>
              </div>
              <KeyboardArrowRightIcon />
            </div>
            <div className="flex flex-col p-5 border my-8 rounded ">
              <p className="text-sm text-gray-400">
                Select a service
                ..................................................................
              </p>
              <span className="grid grid-cols-3 my-5 gap-3 px-2 ">
                {data1.map((i) => {
                  return (
                    <div
                      onClick={() => setTypeId(i.id)}
                      className="flex flex-col justify-center items-center hover:underline"
                    >
                      <img
                        src={i.image}
                        alt=""
                        className={classNames(
                          i.id === typeId
                            ? "border-2 border-blue-500 rounded h-10 w-20"
                            : "rounded h-10 w-20"
                        )}
                      />
                      <p className="text-sm">{i.name}</p>
                    </div>
                  );
                })}
              </span>
              <span>
                <Stack size="small" className="!flex !items-end">
                  <Pagination
                    count={page.total_pages}
                    onChange={handleChange}
                  />
                </Stack>
              </span>
            </div>
          </div>
          <div className="flex flex-col lg:w-[65%] ">
            <img
              src={data?.service_sub_type?.image}
              alt=""
              className="h-[500px] rounded-md my-3"
            />

            <div className="lg:flex flex-row my-5 overflow-y-auto">
              <div className="flex flex-col border px-5 lg:w-[60%]">
                <p className="text-xl flex items-start font-bold mt-10">
                  {data?.service_sub_type?.name}
                </p>
                <div className="flex gap-5 my-3">
                  <p className="text-violet-500 font-bold text-sm  flex items-start ">
                    {data?.service_sub_type?.name} Guide
                  </p>
                  <KeyboardArrowRightIcon className="!text-violet-500" />
                </div>
                {data?.data?.map((item) => {
                  return (
                    <>
                      <div className="flex flex-col my-5 cursor-pointer">
                        <div className="flex">
                          <div
                            onClick={() =>
                              navgate(`/number_of_product/${item?.id}`)
                            }
                            className="w-[60%] "
                          >
                            {/* <p
                              style={{ fontSize: "12px" }}
                              className="bg-green-100 flex items-start w-40 text-green-900 py-1 rounded-md px-2 font-bold "
                            >
                              RS. 359 PER BATHROOM
                            </p> */}
                            <p className=" flex items-start font-bold ">
                              {item?.name}
                            </p>
                            <span className="flex gap-5">
                              <svg
                                viewBox="0 0 20 20"
                                fill="#572AC8"
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M18.333 10a8.333 8.333 0 11-16.667 0 8.333 8.333 0 0116.667 0zm-7.894-4.694A.476.476 0 009.999 5a.476.476 0 00-.438.306L8.414 8.191l-2.977.25a.48.48 0 00-.414.342.513.513 0 00.143.532l2.268 2.033-.693 3.039a.51.51 0 00.183.518.458.458 0 00.528.022L10 13.298l2.548 1.629a.458.458 0 00.527-.022.51.51 0 00.184-.518l-.693-3.04 2.268-2.032a.513.513 0 00.143-.532.48.48 0 00-.415-.342l-2.976-.25-1.147-2.885z"
                                  fill="#572AC8"
                                ></path>
                              </svg>
                              <p className="text-gray-400 text-sm border-b">
                                4.80 (815K reviews)
                              </p>
                            </span>
                            <span className="flex gap-3 my-3">
                              <p className="text-sm">₹{item?.price} </p>
                              <p className="text-sm text-gray-500">
                                Cgst: {item?.cgst}%{" "}
                              </p>
                              <p className="text-sm text-gray-500">
                                Sgst: {item?.sgst}%
                              </p>
                            </span>
                            <span className="flex gap-3">
                              <svg
                                viewBox="0 0 16 16"
                                fill="#07794C"
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3 mt-1"
                              >
                                <path
                                  d="M15 7.929L8.472 1.4a.997.997 0 00-.904-.274l-5.04 1.008a.5.5 0 00-.393.393l-1.008 5.04a.998.998 0 00.274.904L7.928 15a.999.999 0 001.414 0L15 9.343a.999.999 0 000-1.414zM5.25 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                  fill="#07794C"
                                ></path>
                              </svg>
                              <p
                                style={{ fontSize: "12px" }}
                                className="text-gray-500"
                              >
                                {item.service_plan}
                              </p>
                            </span>
                          </div>
                          <div className="w-[40%] flex flex-col ">
                            <p
                              onClick={() => navgate(`/form/${item.id}`)}
                              className="py-1 w-32 border my-2 hover:bg-green-200 rounded-md"
                            >
                              Subscription
                            </p>
                            <img
                              src={item?.image}
                              alt=""
                              className="h-32 w-32"
                            />
                          </div>
                        </div>
                        <hr />

                        <span className="pl-5">
                          <p
                            style={{ fontSize: "12px" }}
                            className="flex items-start text-gray-500"
                          >
                            • {item.description}
                          </p>
                        </span>
                        {/* <p className="font-bold text-violet-600 flex items-start my-3 hover:bg-gray-100 w-24">
                          View details
                        </p> */}
                      </div>
                      <hr />
                    </>
                  );
                })}
                {/* {see &&
                  data?.data?.slice(2)?.map((item) => {
                    return (
                      <>
                        <div className="flex flex-col my-5 cursor-pointer">
                          <div className="flex">
                            <div
                              onClick={() =>
                                navgate(`/number_of_product/${item?.id}`)
                              }
                              className="w-[60%] "
                            >
                              <p
                                style={{ fontSize: "12px" }}
                                className="bg-green-100 flex items-start w-40 text-green-900 py-1 rounded-md px-2 font-bold "
                              >
                                RS. 359 PER BATHROOM
                              </p>
                              <p className=" flex items-start font-bold ">
                                {item?.name}
                              </p>
                              <span className="flex gap-5">
                                <svg
                                  viewBox="0 0 20 20"
                                  fill="#572AC8"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M18.333 10a8.333 8.333 0 11-16.667 0 8.333 8.333 0 0116.667 0zm-7.894-4.694A.476.476 0 009.999 5a.476.476 0 00-.438.306L8.414 8.191l-2.977.25a.48.48 0 00-.414.342.513.513 0 00.143.532l2.268 2.033-.693 3.039a.51.51 0 00.183.518.458.458 0 00.528.022L10 13.298l2.548 1.629a.458.458 0 00.527-.022.51.51 0 00.184-.518l-.693-3.04 2.268-2.032a.513.513 0 00.143-.532.48.48 0 00-.415-.342l-2.976-.25-1.147-2.885z"
                                    fill="#572AC8"
                                  ></path>
                                </svg>
                                <p className="text-gray-400 text-sm border-b">
                                  4.80 (815K reviews)
                                </p>
                              </span>
                              <span className="flex gap-3 my-3">
                                <p className="text-sm">₹{item?.price} </p>
                                <p className="text-sm text-gray-500">
                                  Cgst: {item?.cgst}%{" "}
                                </p>
                                <p className="text-sm text-gray-500">
                                  Sgst: {item?.sgst}%
                                </p>
                              </span>
                              <span className="flex gap-3">
                                <svg
                                  viewBox="0 0 16 16"
                                  fill="#07794C"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-3 w-3 mt-1"
                                >
                                  <path
                                    d="M15 7.929L8.472 1.4a.997.997 0 00-.904-.274l-5.04 1.008a.5.5 0 00-.393.393l-1.008 5.04a.998.998 0 00.274.904L7.928 15a.999.999 0 001.414 0L15 9.343a.999.999 0 000-1.414zM5.25 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                    fill="#07794C"
                                  ></path>
                                </svg>
                                <p
                                  style={{ fontSize: "12px" }}
                                  className="text-gray-500"
                                >
                                  {item.service_plan}
                                </p>
                              </span>
                            </div>
                            <div className="w-[40%] flex flex-col ">
                              <p
                                onClick={() => navgate(`/form/${item.id}`)}
                                className="py-1 w-32 border my-2 hover:bg-green-200 rounded-md"
                              >
                                Subscription
                              </p>
                              <img
                                src={item?.image}
                                alt=""
                                className="h-32 w-32"
                              />
                            </div>
                          </div>
                          <hr />

                          <span className="pl-5">
                            <p
                              style={{ fontSize: "12px" }}
                              className="flex items-start text-gray-500"
                            >
                              • {item.description}
                            </p>
                          </span>
                          <p className="font-bold text-violet-600 flex items-start my-3 hover:bg-gray-100 w-24">
                            View details
                          </p>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                {see ? (
                  <p
                    onClick={() => {
                      setSee(false);
                      abc();
                    }}
                    className="text-blue-400 font-bold hover:underline cursor-pointer"
                  >
                    Show Less...
                  </p>
                ) : (
                  <p
                    onClick={() => setSee(true)}
                    className="text-blue-400 font-bold hover:underline cursor-pointer"
                  >
                    Show More..
                  </p>
                )} */}
              </div>
              <div className="flex flex-col lg:my-0 my-5  px-10">
                <div className="flex flex-col justify-center items-center border py-1 px-5 rounded-xl  ">
                  <svg
                    viewBox="0 0 128 96"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-32 w-32"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M77.5 34a.5.5 0 01-.5.5h-2.5V30a.5.5 0 011 0v3.5H77a.5.5 0 01.5.5z"
                      fill="#FFD47F"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M79.5 34a.5.5 0 01-.5.5h-2.5V30a.5.5 0 011 0v3.5H79a.5.5 0 01.5.5z"
                      fill="#FFD47F"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M73 69a1 1 0 011 1v1H61a1 1 0 00-1 1v7h-2v-7a3 3 0 013-3h12zm3 2h9a1 1 0 011 1v7h2v-7a3 3 0 00-3-3h-9.17c.11.313.17.65.17 1v1z"
                      fill="#E2E2E2"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M60 60v10h-2V60h2z"
                      fill="#E2E2E2"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M72 72a1 1 0 00-1-1H47a1 1 0 00-1 1v7h-2v-7a3 3 0 013-3h24a3 3 0 013 3v7h-2v-7z"
                      fill="#E2E2E2"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M74 70v9h-2v-9h2z"
                      fill="#E2E2E2"
                    ></path>
                    <path
                      d="M50 79a5 5 0 11-10 0 5 5 0 0110 0zM64 79a5 5 0 11-10 0 5 5 0 0110 0zM78 79a5 5 0 11-10 0 5 5 0 0110 0zM92 79a5 5 0 11-10 0 5 5 0 0110 0z"
                      fill="#757575"
                    ></path>
                    <path
                      d="M48 79a3 3 0 11-6 0 3 3 0 016 0zM62 79a3 3 0 11-6 0 3 3 0 016 0zM76 79a3 3 0 11-6 0 3 3 0 016 0zM90 79a3 3 0 11-6 0 3 3 0 016 0z"
                      fill="#EEE"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M74 60v10h-2V60h2z"
                      fill="#E2E2E2"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M30.832 25.445l8 12-1.664 1.11-8-12 1.664-1.11zm16 0l8 12-1.664 1.11-8-12 1.664-1.11z"
                      fill="#CBCBCB"
                    ></path>
                    <path
                      d="M44 34h52l-5.694 30.369A2 2 0 0188.34 66H53.32a4 4 0 01-3.932-3.263L44 34z"
                      fill="#CBCBCB"
                    ></path>
                    <path
                      d="M34 34h48l-6 32H41.66a2 2 0 01-1.966-1.631L34 34z"
                      fill="#E2E2E2"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M46 40h-2v7.059h2V40zm0 12.941h-2V60h2v-7.059zM50 40h2v7.059h-2V40zm2 12.941h-2V60h2v-7.059zM56 40h2v7.059h-2V40zm2 12.941h-2V60h2v-7.059zM62 40h2v7.059h-2V40zm2 12.941h-2V60h2v-7.059zM68 40h2v7.059h-2V40zm2 12.941h-2V60h2v-7.059z"
                      fill="#fff"
                    ></path>
                    <path d="M24 24h28v4H24v-4z" fill="#97674E"></path>
                    <path
                      d="M78 20h6v4a6 6 0 01-6 6V20zM78 15a3 3 0 116 0v5h-6v-5zM78 30V18L66 30h12z"
                      fill="#997BED"
                    ></path>
                    <path d="M88 16l-4-1v2l4-1z" fill="#FFD47F"></path>
                    <path
                      d="M81 15a1 1 0 112 0 1 1 0 01-2 0z"
                      fill="#0F0F0F"
                    ></path>
                    <path
                      d="M72 30h-6l12-12v6a6 6 0 01-6 6z"
                      fill="#6E42E5"
                    ></path>
                  </svg>
                  <p style={{ fontSize: "12px" }} className="text-gray-500">
                    No items in your cart
                  </p>
                </div>
                <div className="flex flex-col border p-5 rounded-xl my-5  ">
                  <span className="flex gap-1">
                    <span>
                      <svg
                        className="h-12 w-12"
                        viewBox="0 0 24 24"
                        fill="#05945B"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.75 8.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM14.75 15.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
                          fill="#05945B"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm6.5-5.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm7 7a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-8.93 3.12l9.9-9.9 1.06 1.06-9.9 9.9-1.06-1.06z"
                          fill="#05945B"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <p style={{ fontSize: "12px" }} className="font-bold">
                        20% off on Kotak Silk cards
                      </p>
                      <p style={{ fontSize: "12px" }} className="text-gray-500">
                        20% off up to INR 350{" "}
                      </p>
                    </span>
                  </span>
                  <span className="flex gap-5 my-1">
                    <p>View More Offers</p>
                    <KeyboardArrowDownIcon className="!text-violet-600" />
                  </span>
                </div>
                <div className="flex flex-col border p-5 rounded-xl my-5  ">
                  <p className="font-bold flex items-start">UC Promise</p>
                  <span className="flex gap-2">
                    <span>
                      <span className="flex gap-1 ">
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.75 15.836l9.793-9.793 1.414 1.415-10.5 10.5a1 1 0 01-1.414 0l-5.25-5.25 1.414-1.415 4.543 4.543z"
                            fill="#0F0F0F"
                          ></path>
                        </svg>
                        <p style={{ fontSize: "12px" }}>
                          Verified Professionals
                        </p>
                      </span>
                      <span className="flex gap-1 ">
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.75 15.836l9.793-9.793 1.414 1.415-10.5 10.5a1 1 0 01-1.414 0l-5.25-5.25 1.414-1.415 4.543 4.543z"
                            fill="#0F0F0F"
                          ></path>
                        </svg>
                        <p style={{ fontSize: "12px" }}>Safe Chemicals</p>
                      </span>
                      <span className="flex gap-1 ">
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.75 15.836l9.793-9.793 1.414 1.415-10.5 10.5a1 1 0 01-1.414 0l-5.25-5.25 1.414-1.415 4.543 4.543z"
                            fill="#0F0F0F"
                          ></path>
                        </svg>
                        <p style={{ fontSize: "12px" }}>Mess Free Experience</p>
                      </span>
                    </span>

                    <img
                      src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_category/w_64,dpr_1,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/home-screen/1686898394662-4bf96e.jpeg"
                      alt=""
                      className="h-10 w-10"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
