import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import login from "../../Ass/login.jpg";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { TextField } from "@mui/material";
export default function Login() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [key, setKey] = useState([]);
  const [otp, setOtp] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [email1, setEmail1] = useState([]);
  const [message, setMessage] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  // const handleChangeOtp = (event) => {
  //   setOtp(event.target.value);
  // };

  const SendOto = (event) => {
    event.preventDefault();
    axios
      .post(
        "https://b1.bhaaraterp.com/customer-pannel/get-customer-login-otp-api/",
        {
          email_or_mobile: email,
        }
      )
      .then((response) => {
        setKey(response.data.otp_key);
        setEmail1(response.data.email);
        response.data.response_code === 200 && setChecked(false);
        setMessage(response.data.message);
        setEmail("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogin = (event) => {
    event.preventDefault();
    const fd = new FormData();
    fd.append(`${key}`, otp);
    fd.append("email", email1);
    axios
      .post(
        `https://b1.bhaaraterp.com/customer-pannel/customer-login-api/`,
        fd,
        {}
      )
      .then((response) => {
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("customersToken", response.data.token);
        localStorage.setItem("customer_name", response.data.customer_name);
        localStorage.setItem(
          "store_id",
          response.data.store_list?.[0].store_id
        );
        setMessage(response.data.message);
        response.data.response_code === 200 && navigate("/");
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <p
        onClick={handleClickOpen}
        className="text-white font-bold hover:underline"
      >
        Login
      </p>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            className:
              "!min-w-[50%] !min-h-[50%] flex gap-3 flex-col !p-5 !bg-red-50",
          }}
        >
          <div
            elevation={0}
            className="  !rounded-none  flex flex-col gap-4 justify-center items-center my-5 lg:my-0"
          >
            <p className="lg:text-3xl text-5xl mt-5 font-semibold underline">
              Sign In
            </p>
            <p className="text-red-500">{message}</p>
            <div className="gap-5">
              <img
                className="  rounded-md lg:block hidden"
                src="https://bhaaraterp.s3.ap-south-1.amazonaws.com/logobhaarat.svg"
                alt=""
              />
              <span className="my-5">
                {checked ? (
                  <>
                    <form
                      className="flex flex-col justify-center items-center"
                      onSubmit={SendOto}
                    >
                      <TextField
                        label="Email/Number"
                        type="text"
                        id="email"
                        variant="filled"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        className="w-[100%] py-5"
                      />
                      <Button
                        size="large"
                        type="submit"
                        className="!rounded w-[100%] !capitalize !my-10 !bg-green-300"
                        disableElevation
                      >
                        Send
                      </Button>
                    </form>
                  </>
                ) : (
                  <>
                    <form
                      className="flex flex-col justify-center items-center"
                      onSubmit={handleLogin}
                    >
                      <TextField
                        label="Otp"
                        type="text"
                        variant="filled"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="!w-[100%]"
                      />
                      <Button
                        size="large"
                        type="submit"
                        className="!rounded w-[100%] !capitalize !my-10 !bg-violet-300"
                        disableElevation
                      >
                        Sign In
                      </Button>
                    </form>
                  </>
                )}
              </span>
            </div>

            <p>
              Don't you have any acount?
              <Link
                className="underline pl-2 text-blue-400"
                onClick={() => setChecked(true)}
              >
                Resend
              </Link>
            </p>
          </div>
        </Dialog>
      </div>
    </>
  );
}
