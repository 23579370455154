import React from "react";
import Footer from "../Shared/Footer";
import { KeyboardArrowUp } from "@mui/icons-material";

const Layout = ({ component }) => {
  const handleTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="w-full h-full !rounded-none !bg-white">{component}</div>
      <div
        color="inherit"
        onClick={handleTop}
        className="!fixed lg:!block !hidden !p-2 !z-50 bottom-10 right-10 bg-gray-300 rounded-full"
      >
        <KeyboardArrowUp fontSize="large" />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
