import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/API_URLS";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Shared/Header";
import { useFormik } from "formik";
import MuiSelect from "../../Shared/MuiSelect";

import service from "../../Ass/service.avif";

export default function NumberOfProduct() {
  const { id } = useParams();
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [year, setYear] = useState("");
  const [test, setTest] = useState(false);
  const [age, setAge] = useState("");
  const [sub, setSub] = useState("");
  const [data, setData] = useState([]);
  const [cuntryId, setCuntryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cuntry, setCuntry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [que, setQus] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [age1, setAge1] = React.useState("");
  const [list, setList] = useState([]);
  const [masge, setMasge] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setAge1(event.target.value);
  };
  const handleChange1 = (event) => {
    setSub(event.target.value);
  };
  const ServiceTypeListr = () => {
    axiosInstance
      .get(API_URLS.serviceId + `?service_id=${id}`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    ServiceTypeListr();
  }, [id]);
  const Cuntry = () => {
    axiosInstance
      .get(API_URLS.Cuntry1)
      .then((response) => {
        setCuntry(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    Cuntry();
  }, []);
  const State = () => {
    axiosInstance
      .get(API_URLS.Cuntry1 + `?country_id=${cuntryId}`)
      .then((response) => {
        setState(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    cuntryId && State();
  }, [cuntryId]);
  const City = () => {
    axiosInstance
      .get(API_URLS.Cuntry1 + `?country_id=${cuntryId}&state_id=${stateId}`)
      .then((response) => {
        setCity(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    stateId && City();
  }, [stateId]);

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id)
        ? prevSelectedRow.filter((i) => i !== id)
        : [...prevSelectedRow, id]
    );
    setTest(true);
  };
  const initialValue1 = {
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
    index: "",
    pincode: "",
    address: "",
    country: "",
    state: "",
    city: "",
    date: "",
  };

  const formik = useFormik({
    initialValues: initialValue1,

    onSubmit: (values, { resetForm }, action) => {
      const fd = {
        first_name: formik.values.first_name,
        last_name: formik.values.last_name,
        mobile_no: formik.values.mobile_no,
        email: formik.values.email,
        mobile: formik.values.mobile,
        pincode: formik.values.pincode,
        address: formik.values.address,
        country: formik.values.country,
        state: formik.values.state,
        city: formik.values.city,
        date: formik.values.date,
        service_id: id,
        age_of_product: age,
        related_problem_id: selectedIds,
        book_service_type: age1,
        quantity: year,
      };
      axiosInstance
        .post(API_URLS.Book, fd)
        .then((response) => {
          alert(response.data.message);
          response.data.response_code === 200 && navigate("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const CheckSubscriptionList = () => {
    axiosInstance
      .get(
        API_URLS.checkSub +
          `?email=${formik.values.email}&mobile_no=${formik.values.mobile_no}&service_id=${id}`
      )
      .then((response) => {
        setList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CheckSubscription = () => {
    const fd = new FormData();
    fd.append("customer_id", list.customer);
    fd.append("service_id", id);
    fd.append("invoice_id", sub);
    axiosInstance
      .post(API_URLS.checkSub, fd)
      .then((response) => {
        setMasge(response.data.message);
        response.data.response_code === 201 && setAge1("One Time Purchase");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    sub && CheckSubscription();
  }, [sub]);
  return (
    <>
      <Header />
      <div className="grid  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 px-10 bg-[#f8f8f8] ">
        <div className="flex flex-col justify-center sm:w-full items-center ">
          <div className="flex flex-col justify-center items-center ">
            <h1 className="flex text-2xl font-semibold ">Book Now</h1>

            {open ? (
              <div className="lg:my-20 my-20">
                {open1 ? (
                  <div className=" shadow-md lg:px-52 px-10 py-10 lg:py-20 lg:my-10 border">
                    <p className="text-3xl font-bold underline">
                      Number of Product
                    </p>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        onChange={(e) => setYear(e.target.value)}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="ONE"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="TWO"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="THREE"
                        />
                        <FormControlLabel
                          value="4"
                          control={<Radio />}
                          label="MORE THAN THREE"
                        />
                      </RadioGroup>
                    </FormControl>
                    {year && (
                      <p
                        className="px-10 py-2 bg-green-400 cursor-pointer my-5 rounded-md"
                        onClick={() => setOpen1(false)}
                      >
                        Next
                      </p>
                    )}
                  </div>
                ) : (
                  <div className=" shadow-md lg:px-52 px-10 py-10 lg:py-20 lg:my-10 border">
                    <p className="text-3xl font-bold underline">
                      Age of Product
                    </p>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        onChange={(e) => setAge(e.target.value)}
                      >
                        <FormControlLabel
                          value="BELOW ONE YEAR"
                          control={<Radio />}
                          label="BELOW ONE YEAR"
                        />
                        <FormControlLabel
                          value="ABOVE TWO YEAR"
                          control={<Radio />}
                          label="ABOVE TWO YEAR"
                        />
                        <FormControlLabel
                          value="ABOVE THREE YEAR"
                          control={<Radio />}
                          label="ABOVE THREE YEAR"
                        />
                        <FormControlLabel
                          value="ABOVE FOUR YEAR"
                          control={<Radio />}
                          label="ABOVE FOUR YEAR"
                        />
                      </RadioGroup>
                    </FormControl>
                    {age && (
                      <p
                        className="px-10 py-2 bg-purple-500 cursor-pointer my-5 rounded-md"
                        onClick={() => setOpen(false)}
                      >
                        Next
                      </p>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="lg:my-20">
                {open2 ? (
                  <div className=" shadow-md lg:px-52 px-10 py-10 my-20 lg:py-20 lg:my-10 border">
                    <p className="text-3xl font-bold underline">
                      Work / Problem Details
                    </p>
                    {data.map((item) => {
                      return (
                        <span className="flex gap-10">
                          <Checkbox
                            value={item.id}
                            checked={
                              selectedIds.filter((id) => id === item.id)
                                ?.length === 1
                                ? true
                                : false
                            }
                            onChange={() => handleSelectRow(item.id)}
                          />
                          <p className="font-bold">{item?.name}</p>
                        </span>
                      );
                    })}
                    {que && (
                      <span className="flex gap-10">
                        <Checkbox
                          value={0}
                          checked={
                            selectedIds.filter((id) => id === 0)?.length === 1
                              ? true
                              : false
                          }
                          onChange={() => handleSelectRow(0)}
                        />
                        <p className="font-bold">{que}</p>
                      </span>
                    )}

                    <input
                      className="h-10 outline-none border-2 border-black pl-5 rounded"
                      type="text"
                      placeholder="Enter Your question"
                      onChange={(e) => setQus(e.target.value)}
                    />

                    {test && (
                      <p
                        className="px-10 py-2 bg-violet-500 cursor-pointer my-5 rounded-md"
                        onClick={() => setOpen2(false)}
                      >
                        Next
                      </p>
                    )}
                  </div>
                ) : (
                  <div className=" shadow-md lg:px-10   px-10   border">
                    <Link to="/">
                      <p className="text-red-900 my-500 bg-green-300 border hover:underline cursor-pointer">
                        {masge}
                      </p>
                    </Link>
                    <p className="text-3xl font-bold underline my-2">
                      Customer Information
                    </p>

                    <form
                      onSubmit={formik.handleSubmit}
                      className="flex flex-col w-full my-10"
                    >
                      <div className="lg:flex  lg:lg:gap-20">
                        <TextField
                          className="!my-2"
                          id="first_name"
                          name="first_name"
                          value={formik.values["first_name"]}
                          onChange={formik.handleChange}
                          onBlur={formik.onBlur}
                          label="First Name"
                          variant="standard"
                          type="text"
                        />
                        <TextField
                          className="!my-2"
                          id="last_name"
                          name="last_name"
                          value={formik.values["last_name"]}
                          onChange={formik.handleChange}
                          onBlur={formik.onBlur}
                          label="Last Name"
                          variant="standard"
                          type="text"
                        />
                      </div>
                      <div className="lg:flex lg:gap-20">
                        <TextField
                          className="!my-2"
                          id="mobile_no"
                          name="mobile_no"
                          value={formik.values["mobile_no"]}
                          onChange={formik.handleChange}
                          onBlur={formik.onBlur}
                          label="Mobile No"
                          variant="standard"
                          type="number"
                        />
                        <TextField
                          className="!my-2"
                          id="email"
                          name="email"
                          value={formik.values["email"]}
                          onChange={formik.handleChange}
                          onBlur={formik.onBlur}
                          label="Email"
                          variant="standard"
                          type="email"
                        />
                      </div>
                      <div className="lg:flex lg:gap-20 my-5">
                        <MuiSelect
                          className="!my-2 !border-none"
                          placeholder="country"
                          id="country"
                          name="country"
                          value={formik.values.country || ""}
                          onChange={formik.handleChange}
                          variant="standard"
                        >
                          {cuntry?.map((customer) => {
                            return (
                              <MenuItem
                                onClick={() => setCuntryId(customer.id)}
                                key={customer.id}
                                value={customer.name}
                              >
                                {customer.name}
                              </MenuItem>
                            );
                          })}
                        </MuiSelect>

                        <MuiSelect
                          className="!my-2"
                          placeholder="state"
                          id="state"
                          name="state"
                          value={formik.values.state || ""}
                          onChange={formik.handleChange}
                        >
                          {state?.map((customer) => {
                            return (
                              <MenuItem
                                onClick={() => setStateId(customer.id)}
                                key={customer.id}
                                value={customer.state}
                              >
                                {customer.state}
                              </MenuItem>
                            );
                          })}
                        </MuiSelect>
                        <MuiSelect
                          className="!my-2 "
                          placeholder="city"
                          id="city"
                          name="city"
                          value={formik.values.city || ""}
                          onChange={formik.handleChange}
                        >
                          {city?.map((customer) => {
                            return (
                              <MenuItem key={customer.id} value={customer.city}>
                                {customer.city}
                              </MenuItem>
                            );
                          })}
                        </MuiSelect>
                      </div>

                      <div className="lg:flex lg:gap-20">
                        <TextField
                          className="!my-2"
                          id="address"
                          name="address"
                          value={formik.values["address"]}
                          onChange={formik.handleChange}
                          onBlur={formik.onBlur}
                          label="Address"
                          variant="standard"
                          type="text"
                        />{" "}
                        <TextField
                          className="!my-2"
                          id="pincode"
                          name="pincode"
                          value={formik.values["pincode"]}
                          onChange={formik.handleChange}
                          onBlur={formik.onBlur}
                          label="pincode"
                          variant="standard"
                          type="number"
                        />
                      </div>
                      <TextField
                        className="!my-5"
                        id="date"
                        name="date"
                        value={formik.values["name"]}
                        onChange={formik.handleChange}
                        onBlur={formik.onBlur}
                        variant="standard"
                        type="date"
                      />
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 250 }}
                      >
                        <p className="font-bold underline my-1">
                          Select ServiceS Type
                        </p>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={age1}
                          onChange={handleChange}
                          className="!my-5"
                        >
                          {/* <MenuItem value="">Select ServiceS Type</MenuItem> */}
                          <MenuItem value="One Time Purchase">
                            One Time Purchase
                          </MenuItem>
                          <MenuItem
                            onClick={() => CheckSubscriptionList()}
                            value="Subscription"
                          >
                            Subscription
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {formik.values.email && age1 === "Subscription" && (
                        <div className="flex flex-col justify-center items-center">
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, minWidth: 250 }}
                          >
                            <p className="font-bold underline my-1">
                              Select ServiceS Type
                            </p>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={sub}
                              onChange={handleChange1}
                              className="!my-5"
                            >
                              {list?.invoice?.map((i) => {
                                return (
                                  <MenuItem value={i?.id}>
                                    {i?.invoice_no}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      )}

                      <button
                        className="py-2 px-3 bg-green-500 rounded my-5"
                        type={"submit"}
                      >
                        Book
                      </button>
                    </form>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="lg:my-20 my-20">
          <img className="hidden lg:flex h-[90%]" src={service} alt="" />
        </div>
      </div>
    </>
  );
}
