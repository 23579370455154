export const API_URLS = {
  serviceTypeList: "support_page/service-type-list-api-of-bhaaraterp/",
  serviceSubTypeList: "support_page/service-sub-type-list-api-of-bhaaraterp/",
  serviceTypeListWithId:
    "support_page/service-list-without-pagination-api-for-bhaaraterp/",
  serviceId: "support_page/service-related-problem-list-api-for-bhaaraterp/",
  Book: "support_page/book-service-api-for-bhaaraterp/",
  Cuntry1: "country-state-city-list-api-of-bhaaraterp/",
  checkSub: "support_page/check-customer-for-subscription-api-of-bhaaraterp/",
  subscription: "support_page/buy-service-api-for-bhaaraterp/",
  serviceTypeAndSubTypeList:
    "support_page/service-type-and-sub-type-list-api-of-bhaaraterp/",
};
