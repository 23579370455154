import { Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useEffect, useState } from "react";
import india from "../../Ass/india.png";
import SearchIcon from "@mui/icons-material/Search";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Header from "../../Shared/Header";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/API_URLS";

import Login from "../../Shared/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function Home() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState(false);
  const [data1, setData1] = useState([]);
  const [data, setData] = useState([]);
  const navegate = useNavigate();
  const token = localStorage.getItem("customersToken");
  const name = localStorage.getItem("customer_name");
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.clear();
    navegate("/");
  };
  const ServiceTypeListr = () => {
    axiosInstance
      .get(API_URLS.serviceTypeList)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    ServiceTypeListr();
  }, []);

  const ServiceSubTypeListr = () => {
    axiosInstance
      .get(API_URLS.serviceTypeAndSubTypeList)
      .then((response) => {
        setData1(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    ServiceSubTypeListr();
  }, []);

  return (
    <div className="overflow-y-hidden">
      {state && <Header />}
      <div
        className="bg-cover bg-center min-h-[650px] bg-transparent-25 "
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/urbanclap/image/upload/w_1800,q_auto:low,f_auto,fl_progressive:steep/images/growth/home-screen/1615375782838-f890f8.jpeg')",
        }}
        onMouseEnter={() => setState(false)}
      >
        <div className="flex flex-row justify-between lg:px-16 px-5 ">
          <img
            src="https://bhaaraterp.s3.ap-south-1.amazonaws.com/logobhaarat.svg"
            alt=""
            className="h-20 w-60  mt-5"
          />
          <div className="flex gap-5 mt-5">
            {token ? (
              <>
                <p
                  onClick={() => navegate("/book_heistry")}
                  className="text-white font-bold hover:underline cursor-pointer"
                >
                  Book Heistry
                </p>
                <p
                  onClick={() => navegate("/subscription")}
                  className="text-white font-bold hover:underline cursor-pointer"
                >
                  Subscription
                </p>
                <span onClick={handleClick1} className="flex">
                  <AccountCircleIcon className="!text-white" />
                </span>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl1}
                  open={open1}
                  onClose={handleClose1}
                >
                  <MenuItem onClick={handleClose}>
                    <p className="font-bold  cursor-pointer">Hi {name}</p>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <p
                      onClick={handleLogout}
                      className=" font-bold hover:underline cursor-pointer text-red-500"
                    >
                      Log Out
                    </p>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Login />
            )}
          </div>
        </div>
        <span className=" flex flex-col justify-center items-center mt-32">
          <p className="font-bold text-5xl text-white my-5">
            Home services, on demand.
          </p>
        </span>
        <div className="flex justify-center items-center gap-5">
          {/* <div
            onClick={handleClick}
            className="flex py-4 w-56 bg-white justify-between px-3 rounded-lg shadow-md "
          >
            <FmdGoodOutlinedIcon />
            <div className="flex gap-5">
              <img className="h-6 w-6" src={india} alt="" />
              <p>Lucknow</p>
            </div>
            <ExpandMoreIcon className="!text-red-300" align="right" />
          </div> */}
          <div className="mt-6 lg:block hidden">
            {/* <div className="flex min-w-[500px] bg-white py-4 rounded-lg shadow-md px-5 gap-5">
              <SearchIcon />{" "}
              <input
                type="text"
                placeholder="Search for Services"
                className="outline-none"
              />
            </div> */}
            <div className="flex gap-2 ">
              <p className="text-white underline">Salon For Men,</p>
              <p className="text-white underline">Men's Theropied,</p>
              <p className="text-white underline">Home Painting</p>{" "}
              <p className="text-white">etc</p>
            </div>
          </div>
        </div>
      </div>
      <span className="lg:block hidden">
        <div className="flex  flex-col justify-center items-center ">
          <div
            style={{
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            className="grid lg:grid-cols-6  max-h-[300px] w-[900px]  p-10 absolute bg-white items-center rounded"
          >
            {data?.map((item) => {
              return (
                <div
                  onClick={() => navegate(`/detailspage/${item?.id}/${0}`)}
                  className="flex flex-col justify-center items-center p-5 h-32 w-32 rounded hover:bg-gray-100"
                >
                  <img src={item?.image} alt="" className="h-8 w-8" />
                  <p className="my-3">{item?.service_type}</p>
                </div>
              );
            })}
          </div>
        </div>
      </span>
      {data1.slice(0, 2).map((i) => {
        return (
          <div
            onMouseEnter={() => setState(true)}
            className="flex flex-col justify-center items-center border-b-4 "
          >
            <span className="my-14">
              <p className="text-3xl font-bold underline mt-20">
                {i.service_type}
              </p>
              {/* <p className="text-gray-500 text-xl my-3">
                Expert Technicians at your Doorstep in 2 hours
              </p> */}
            </span>
            <span className="grid lg:grid-cols-5 grid-cols-1 gap-8 p-5   ">
              {i?.service_sub_type?.map((item) => {
                return (
                  <div
                    onClick={() => navegate(`/detailspage/${i?.id}/${item.id}`)}
                  >
                    <img
                      src={item?.image}
                      alt=""
                      className="h-40 w-72 hover:scale-[110%]  hdiv"
                    />
                    <p className="font-bold mt-5 ">{item?.name}</p>
                    <p className="text-gray-400 text-sm"></p>
                  </div>
                );
              })}
            </span>
          </div>
        );
      })}
      <hr className="mt-5" />
      <div className="flex flex-col justify-center items-center border-b-4 ">
        <span className="my-14">
          <p className="text-3xl font-bold ">Best Services</p>
          {/* <p className="text-gray-500 text-xl my-3">
            Hardware and Production| low-contact services
          </p> */}
        </span>
        <span className="flex lg:flex-row flex-col gap-8 p-5">
          {data?.slice(0, 3).map((i) => {
            return (
              <div>
                <img
                  src={i?.image}
                  alt=""
                  className="h-40 w-72 hover:scale-[110%] hdiv  "
                  onClick={() => navegate(`/detailspage/${i?.id}/${0}`)}
                />
                <p className="font-bold mt-5 ">{i?.service_type}</p>
                {/* <p className="text-gray-400 text-sm">Hardware at ₹199</p> */}
              </div>
            );
          })}
        </span>
      </div>

      {/* <div className="flex flex-col justify-center items-center border-b-4 my-5">
        <p className="text-3xl font-bold my-10 ">Home Repairs</p>
        <span className="flex lg:flex-row flex-col gap-8 p-5   ">
          <div>
            <img
              src="https://res.cloudinary.com/urbanclap/image/upload/q_auto,f_auto,fl_progressive:steep,w_532/t_high_res_category/categories/home_screen/carpenter.jpg"
              alt=""
              className="h-40 w-72 hover:scale-[110%]  hdiv"
            />
            <p className="font-bold mt-5 ">Carpenters</p>
            <p className="text-gray-400 text-sm"></p>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/urbanclap/image/upload/q_auto,f_auto,fl_progressive:steep,w_532/t_high_res_category/categories/home_screen/electrician.jpg"
              alt=""
              className="h-40 w-72 hover:scale-[110%]  hdiv"
            />
            <p className="font-bold mt-5 ">Electricians</p>
            <p className="text-gray-400 text-sm"></p>
          </div>

          <div>
            <img
              src="https://res.cloudinary.com/urbanclap/image/upload/q_auto,f_auto,fl_progressive:steep,w_532/t_high_res_category/categories/home_screen/plumber.jpg"
              alt=""
              className="h-40 w-72 hover:scale-[110%]  hdiv"
            />
            <p className="font-bold mt-5 ">Plumbers</p>
            <p className="text-gray-400 text-sm"></p>
          </div>
        </span>
      </div> */}
      {/* <div className="flex border-b-4">
        <img
          src="https://res.cloudinary.com/urbanclap/image/upload/q_auto,f_auto,fl_progressive:steep/t_high_res_template/categories/category_v2/category_a4e46fa0.png"
          alt=""
        />
      </div>
      <div className="flex border-b-4">
        <img
          src="https://res.cloudinary.com/urbanclap/image/upload/q_auto,f_auto,fl_progressive:steep/t_high_res_template/images/growth/home-screen/1624344861242-558286.png"
          alt=""
        />
      </div>
      <div className="flex">
        <img
          src="https://res.cloudinary.com/urbanclap/image/upload/q_auto,f_auto,fl_progressive:steep/t_high_res_template/categories/category_v2/category_3cffdf20.png"
          alt=""
        />
      </div> */}
    </div>
  );
}
