import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/API_URLS";
import { Link, useNavigate } from "react-router-dom";

export default function Header() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState(false);
  const navegate = useNavigate();
  const ServiceTypeListr = () => {
    axiosInstance
      .get(API_URLS.serviceTypeList)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    ServiceTypeListr();
  }, []);
  return (
    <div className="lg:block hidden">
      <div className="flex bg-white fixed top-0 z-50 w-full shadow-2xl px-20  justify-between ">
        <Link to="/">
          <img
            src="https://bhaaraterp.s3.ap-south-1.amazonaws.com/logobhaarat.svg"
            alt=""
            className="h-20 w-60 object-contain my-1"
          />
        </Link>

        <div className="py-4 ">
          <div className="flex min-w-[400px] bg-white py-3 rounded-lg border px-5 gap-5">
            <SearchIcon />{" "}
            <input
              type="text"
              placeholder="Search for Services"
              className="outline-none"
              onChange={(e) => {
                setSearch(e.target.value);
                setSearch1(true);
              }}
            />
          </div>
          {search1 && (
            <div className="flex flex-col absolute bg-red-100 rounded-md my-5 ">
              {data
                .filter((data) =>
                  data.service_type.toLowerCase().includes(search)
                )
                .map((i) => {
                  return (
                    <div
                      onClick={() => {
                        navegate(`/detailspage/${i?.id}/${0}`);
                        setSearch1(false);
                        setSearch("");
                      }}
                      className="flex gap-5 my-2 px-3 hover:bg-red-200 hover:rounded-md  py-3 "
                    >
                      <img src={i.image} alt="" className="h-8 w-8" />
                      <p>{i.service_type}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        {/* {search && (
            <div className="flex flex-col absolute bg-white ">
              {data.map((i) => {
                return (
                  <div className="flex gap-5 my-2">
                    <img src={i.image} alt="" className="h-8 w-8" />
                    <p>{i.service_type}</p>
                  </div>
                );
              })}
            </div>
          )} */}

        <div className="flex flex-row justify-center">
          {data?.slice(0, 3).map((item) => {
            return (
              <div
                onClick={() => navegate(`/detailspage/${item?.id}/${0}`)}
                className="p-3 flex flex-col justify-center items-center hover:bg-gray-100 rounded   "
              >
                <img
                  src={item?.image}
                  alt=""
                  className="h-8 w-8 object-contain"
                />
                <p className="text-sm ">{item?.service_type.slice(0, 7)}...</p>
              </div>
            );
          })}

          <div className="bg-gray-50 p-3 rounded-full flex justify-center items-center">
            <Link to="https://customer.bhaaraterp.com/">
              <p className="text-sm hover:underline font-bold text-blue-500 text-center">
                Wiest Panal
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
