import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import NearMeIcon from "@mui/icons-material/NearMe";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <>
      <div className="bg-black !text-white flex flex-col justify-center items-center px-10">
        <div className="grid lg:grid-cols-5 grid-cols-2 gap-3 my-5 border-b">
          <p className="font-bold text-sm">About Us</p>
          <p className="font-bold text-sm">UC Impact</p>
          <p className="font-bold text-sm">Terms & Condition</p>
          <p className="font-bold text-sm">Privicy Policy</p>
          <p className="font-bold text-sm">Interest-Based Adevertising</p>
          <p className="font-bold text-sm">Anti Discrimination Policy</p>
          <p className="font-bold text-sm">Partner Welfare Policy</p>
          <p className="font-bold text-sm">Information Security Policy</p>
          <p className="font-bold text-sm">Statement & Object</p>
          <p className="font-bold text-sm">Blog</p>
          <p className="font-bold text-sm">Reviews</p>
          <p className="font-bold text-sm">Near Me</p>
          <p className="font-bold text-sm">Careers</p>
          <p className="font-bold text-sm">Contact Us</p>
          <p className="font-bold text-sm">Quick Links</p>
          <p className="font-bold text-sm">RO Water Purifier</p>
        </div>

        <div className="lg:flex justify-between lg:px-52 px-10 gap-10 my-5">
          <div className=" flex flex-col justify-center items-center ">
            <p className="text-2xl font-bold underline my-2">OUR SERVICES</p>
            <p className="lg:px-20">
              BhaaratErp has been developed as an integrated service delivery
              platform with best technology.
            </p>
            <p className=" text-xl font-bold">GET CONNECTED</p>
            <div className="flex gap-3">
              <span className="py-2 px-3 border hover:bg-violet-500">
                <Link to="https://www.facebook.com/bhaaraterp/">
                  <FacebookIcon className="!text-white" />
                </Link>
              </span>
              <span className="py-2 px-3 border hover:bg-violet-500">
                <Link to="#">
                  <TwitterIcon className="!text-white" />
                </Link>
              </span>
              <span className="py-2 px-3 border hover:bg-violet-500">
                <Link to="https://www.linkedin.com/posts/bhaaraterp_erp-erpsoftware-crmsoftware-activity-7100101482498158592-1jXV">
                  <LinkedInIcon className="!text-white" />
                </Link>
              </span>
            </div>
          </div>
          <div>
            <p className="text-2xl font-bold underline my-2">CONTACT US</p>
            <div className="flex my-1 ">
              <EmailIcon />
              <p>Address : </p>
            </div>
            <div className="flex my-1 ">
              <PhoneIcon />
              <p> (+91) 88818 10100</p>
            </div>
            <div className="flex my-1 ">
              <NearMeIcon />
              <Link to="https://bhaaraterp.com/">
                {" "}
                <p> https://bhaaraterp.com/</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
