import Form from "../Components/AgeOfProduct";
import NumberOfProduct from "../Components/NumberOfProduct";
import Subscription from "../Pages/ Subscription";
import BookHeistry from "../Pages/BookHeistry";
import DetailsPage from "../Pages/DetailsPage";
import Home from "../Pages/Home";

const routes = [
  {
    id: 1,
    path: "/",
    element: <Home />,
  },
  {
    id: 2,
    path: "/detailspage/:id/:id1",
    element: <DetailsPage />,
  },
  {
    id: 3,
    path: "/number_of_product/:id",
    element: <NumberOfProduct />,
  },
  {
    id: 4,
    path: "/form/:id",
    element: <Form />,
  },
  {
    id: 5,
    path: "/subscription",
    element: <Subscription />,
  },
  {
    id: 6,
    path: "/book_heistry",
    element: <BookHeistry />,
  },
];

export default routes;
