import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/API_URLS";
import { useFormik } from "formik";
import { MenuItem, TextField } from "@mui/material";
import MuiSelect from "../../Shared/MuiSelect";
import Header from "../../Shared/Header";

export default function Form() {
  const { id } = useParams();
  const [cuntryId, setCuntryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cuntry, setCuntry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const navigate = useNavigate();
  const Cuntry = () => {
    axiosInstance
      .get(API_URLS.Cuntry1)
      .then((response) => {
        setCuntry(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    Cuntry();
  }, []);
  const State = () => {
    axiosInstance
      .get(API_URLS.Cuntry1 + `?country_id=${cuntryId}`)
      .then((response) => {
        setState(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    cuntryId && State();
  }, [cuntryId]);
  const City = () => {
    axiosInstance
      .get(API_URLS.Cuntry1 + `?country_id=${cuntryId}&state_id=${stateId}`)
      .then((response) => {
        setCity(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    stateId && City();
  }, [stateId]);
  const initialValue1 = {
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
    index: "",
    pincode: "",
    address: "",
    country: "",
    state: "",
    city: "",
    date: "",
  };

  const formik = useFormik({
    initialValues: initialValue1,

    onSubmit: (values, { resetForm }, action) => {
      const fd = {
        service_order_type: "Subscription",
        first_name: formik.values.first_name,
        last_name: formik.values.last_name,
        mobile_no: formik.values.mobile_no,
        email: formik.values.email,
        mobile: formik.values.mobile,
        pincode: formik.values.pincode,
        address: formik.values.address,
        country: formik.values.country,
        state: formik.values.state,
        city: formik.values.city,
        date: formik.values.date,
        service_id: id,
        quantity: 1,
      };
      axiosInstance
        .post(API_URLS.subscription, fd)
        .then((response) => {
          alert(response.data.message);
          response.data.response_code === 200 && navigate("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });
  return (
    <>
      <Header />
      <div className="grid  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 px-4 bg-[#f8f8f8] mt-24">
        <div className="">
          <img
            className="hidden lg:flex"
            src="https://www.seekpng.com/png/detail/878-8788622_free-png-download-computer-repair-services-png-images.png"
            alt=""
          />
        </div>

        <div className="flex flex-col justify-center sm:w-full items-center ">
          <div className="flex flex-col justify-center  p-4 text-lg font-bold border shadow-md shad rounded-md bg-white">
            <p className="flex justify-center text-lg font-semibold">
              Subscribe Now
            </p>
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col justify-center items-center"
            >
              <div className="lg:flex  gap-20">
                <TextField
                  className="!my-2"
                  id="first_name"
                  name="first_name"
                  value={formik.values["first_name"]}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  label="First Name"
                  variant="standard"
                  type="text"
                />
                <TextField
                  className="!my-2"
                  id="last_name"
                  name="last_name"
                  value={formik.values["last_name"]}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  label="Last Name"
                  variant="standard"
                  type="text"
                />
              </div>
              <div className="lg:flex gap-20">
                <TextField
                  className="!my-2"
                  id="mobile_no"
                  name="mobile_no"
                  value={formik.values["mobile_no"]}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  label="Mobile No"
                  variant="standard"
                  type="number"
                />
                <TextField
                  className="!my-2"
                  id="email"
                  name="email"
                  value={formik.values["email"]}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  label="Email"
                  variant="standard"
                  type="email"
                />
              </div>
              <div className="lg:flex gap-20 my-5">
                <MuiSelect
                  className="!my-2 !border-none"
                  placeholder="country"
                  id="country"
                  name="country"
                  value={formik.values.country || ""}
                  onChange={formik.handleChange}
                  variant="standard"
                >
                  {cuntry?.map((customer) => {
                    return (
                      <MenuItem
                        onClick={() => setCuntryId(customer.id)}
                        key={customer.id}
                        value={customer.name}
                      >
                        {customer.name}
                      </MenuItem>
                    );
                  })}
                </MuiSelect>

                <MuiSelect
                  className="!my-2"
                  placeholder="state"
                  id="state"
                  name="state"
                  value={formik.values.state || ""}
                  onChange={formik.handleChange}
                >
                  {state?.map((customer) => {
                    return (
                      <MenuItem
                        onClick={() => setStateId(customer.id)}
                        key={customer.id}
                        value={customer.state}
                      >
                        {customer.state}
                      </MenuItem>
                    );
                  })}
                </MuiSelect>
                <MuiSelect
                  className="!my-2 "
                  placeholder="city"
                  id="city"
                  name="city"
                  value={formik.values.city || ""}
                  onChange={formik.handleChange}
                >
                  {city?.map((customer) => {
                    return (
                      <MenuItem key={customer.id} value={customer.city}>
                        {customer.city}
                      </MenuItem>
                    );
                  })}
                </MuiSelect>
              </div>

              <div className="lg:flex gap-20">
                <TextField
                  className="!my-2"
                  id="address"
                  name="address"
                  value={formik.values["address"]}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  label="Address"
                  variant="standard"
                  type="text"
                />{" "}
                <TextField
                  className="!my-2"
                  id="pincode"
                  name="pincode"
                  value={formik.values["pincode"]}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  label="pincode"
                  variant="standard"
                  type="number"
                />
              </div>
              <TextField
                className="!my-5"
                id="date"
                name="date"
                value={formik.values["name"]}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                variant="standard"
                type="date"
              />

              <button
                className="py-2 px-3 bg-green-500 rounded my-5"
                type={"submit"}
              >
                Subscription
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
